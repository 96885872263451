const STEPS = {
  START: "START",
  REGISTER_CONSENT: "REGISTER_CONSENT",
  STATION_CONSENT: "STATION_CONSENT",
  CONSENT: "CONSENT",
  CONSENT_FAIL: "CONSENT_FAIL",
  REGISTER_FORM: "RESISTER_FORM",
  CAMERA_PERMISSION_FAIL: "CAMERA_PERMISSION_FAIL",
  PRE_ENROLL: "PRE_ENROLL",
  ENROLL: "ENROLL",
  PRE_DRIVERLICENSE: "PRE_DRIVERLICENSE",
  DRIVERLICENSE: "DRIVERLICENSE",
  SWITCH_DEVICE: "SWITCH_DEVICE",
  ADDITIONAL_REQUIREMENTS: "ADDITIONAL_REQUIREMENTS",
  SUCCESS: "SUCCESS",
  VERIFICATION_NOT_COMPLETED: "VERIFICATION_NOT_COMPLETED",
  REQUEST_SSN: 'REQUEST_SSN',
  REQUEST_ADDRESS: 'REQUEST_ADDRESS',
  NOT_SUPPORTED: "NOT_SUPPORTED",
  FEEDBACK: 'FEEDBACK',
};

export default STEPS;
